import React from "react";
import Container from "react-bootstrap/Container";
import 'font-awesome/css/font-awesome.min.css';

const openImageInNewTab = () => {
  const imageUrl = require('../editable-stuff/john_and_hannah.jpeg'); // URL of the image you want to open
  const newWindow = window.open(imageUrl, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto py-5 text-center ">
      <Container>
        {props.children}
        <i className="fa fa-code" /> with <i className="fa fa-heart" /> by{" "}
         <span 
            className="badge bg-dark"
            onClick={openImageInNewTab}
            style={{ cursor: 'pointer' }}>
            John Wang
          </span>

      </Container>
    </footer>
  );
};

export default Footer;
