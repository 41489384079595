import { Container } from "react-bootstrap";
// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#9333EA, #3B82F6",
  firstName: "John",
  middleName: "",
  lastName: "Wang",
  message: " ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/jwang307",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/john-n-wang/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/johnwang.png"),
  imageSize: 360,
  message:
    <Container>
      Hi! I'm John, a third-year student at Stanford studying Computer Science (B.S.) and Biomedical Data Science (M.S.). 
      I'm fascinated by the power of computation and ML in various fields of biology, from genetics to structural research. Right now, 
      I'm most interested in protein design and drug discovery. Broadly, I'm also interested in ML systems, graph and probabilistic models. 
      Currently, I'm interested in ML-aided
      {" "}<a
        className="btn btn-outline-warning btn-sm"
        target="_blank"
        rel="noopener noreferrer"
        href="https://evodesign.org/"
        aria-label="Lab Website"
      >
        protein design
      </a>{" "}
      for biological good.
      This summer, I'll be working on implementing brain-based AI at 
      {" "}<a
        className="btn btn-outline-info btn-sm"
        target="_blank"
        rel="noopener noreferrer"
        href="https://numenta.com/"
        aria-label="Numenta"
      >
          Numenta!
      </a>{" "}
      <br></br><br></br>
      In the past, I've worked on these projects: 
      
      <ul style={{listStyleType: 'none', paddingLeft: 0}}>
        <li style={{marginLeft: 20}}>→ Summer 2023 at 
          {" "}<a
            className="btn btn-outline-primary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href="https://virtualitics.com/"
            aria-label="Virtualitics"
          >
            Virtualitics
          </a>{" "}
          as a Data Science intern developing predictive maintenance pipelines with survival analysis models and real-time signal processing.
        </li>
        <li style={{marginLeft: 20}}>→ Summer 2022 as a CURIS scholar at the
            {" "}<a
            className="btn btn-outline-secondary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href="https://drorlab.stanford.edu"
            aria-label="Lab Website"
          >
            Dror Lab
          </a>{" "} exploring ways to modify AlphaFold models for improved ligand docking. Read about some of the work I've been involved in 
          {" "} 
          <a
            className="btn btn-outline-success btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href="https://elifesciences.org/reviewed-preprints/89386"
            aria-label="Paper"
          >
              here
          </a>{". "}
        </li>
        <li style={{marginLeft: 20}}>→ 2020 and 2021 as a research intern at the 
          {" "}<a
            className="btn btn-outline-danger btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.extremeviruses.org/"
            aria-label="PSU Lab Website"
          >
              Extreme Virus Lab
          </a>{" "} creating pipelines for efficient genetic annotation of cruciviruses, an interesting hybrid of DNA and RNA viruses.
        </li>
      </ul>
      <br></br>
      I am a mediocre athlete of many disciplines including table tennis, climbing, running, cycling, and thru-hiking. 
      My Resume contains a complete reference of my work.
    </Container>,
  resume: require("../editable-stuff/CV_wang.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Some Projects",
  gitHubUsername: "jwang307", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/johnwang.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/johnwang.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: false,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 90 },
    { name: "SQL", value: 75 },
    { name: "Data Structures", value: 85 },
    { name: "C/C++", value: 65 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 65 },
    { name: "HTML/CSS", value: 55 },
    { name: "C#", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm always interested in connecting about ML, Biology, and anything else. Feel free to say hi at",
  email: "jwang003@stanford.edu",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
const blog = {
   show: false,
 };

export { navBar, mainBody, about, repos, blog, skills, leadership, getInTouch, experiences };
